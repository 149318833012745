<template>
  <div class="home">
    <h1>{{ msg }}</h1>
    <h2>We make custom bar and bathroom products!</h2>
    <img alt="Peeon.it Urinal Pee Target" src="../assets/urinal.png" width="200">
    <p>Made in the USA! 🇺🇸🏈 The best thing you could ever pee on! This is the ultimate addition to any urinal, at the bar or in the man cave. Each order includes a constructed upright with wire and a football, a urinal pad and an upright holder to be installed below the pad. Pads are tabbed so you can keep track of when you installed them. We have replacement pads at the ready. The uprights are warrantied to last to 6 months! For every football fan of any team! Add that extra “oh my god no way...” to your space today! We have made custom urinal targets too! Please get in contact with us if you need something custom like your own bar logo!</p>
    <p>Be sure to buy our stuff on ebay and amazon! See the links below.</p>
    <a href="https://www.ebay.com/sch/i.html?item=183581008023&rt=nc&_ssn=bkambience" target="_blank">
      <img alt="Peeon.it Urinal Pee Target on ebay" src="../assets/ebay.png" width="200">
    </a>
    <a href="https://www.amazon.com/dp/B07LGCR3N1" target="_blank">
      <img alt="Peeon.it Urinal Pee Target on Amazon" src="../assets/amazon.png" width="200">
    </a>
  </div>
</template>

<script>
export default {
  name: 'Home-Page',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
